export const BEGIN_FLASHCARD_FOLDERS_LOADING_AJAX_CALL = 'BEGIN_FLASHCARD_FOLDERS_LOADING_AJAX_CALL';
export const END_FLASHCARD_FOLDERS_LOADING_AJAX_CALL = 'END_FLASHCARD_FOLDERS_LOADING_AJAX_CALL';
export const FLASHCARD_FOLDERS_LOADING_AJAX_CALL_ERROR = 'FLASHCARD_FOLDERS_LOADING_AJAX_CALL_ERROR';

export const LOAD_FLASHCARD_FOLDERS = 'LOAD_FLASHCARD_FOLDERS';
export const LOAD_FLASHCARD_FOLDER = 'LOAD_FLASHCARD_FOLDER';
export const CREATE_FLASHCARD_FOLDER_SUCCESS = 'CREATE_FLASHCARD_FOLDER_SUCCESS';
export const UPDATE_FLASHCARD_FOLDER_SUCCESS = 'UPDATE_FLASHCARD_FOLDER_SUCCESS';
export const DELETE_FLASHCARD_FOLDER_SUCCESS = 'DELETE_FLASHCARD_FOLDER_SUCCESS';

export const SAVE_FLASHCARD_IN_FOLDER_SUCCESS = 'SAVE_FLASHCARD_IN_FOLDER_SUCCESS';
export const UPDATE_FLASHCARD_IN_FOLDER_SUCCESS = 'UPDATE_FLASHCARD_IN_FOLDER_SUCCESS';
export const DELETE_FLASHCARD_FROM_FOLDER_SUCCESS = 'DELETE_FLASHCARD_FROM_FOLDER_SUCCESS';

export const SSE_INITIALIZED = 'SSE_INITIALIZED';
